<template>
  <div class="wishes">
    <div class="oItem-t">
      <div class="title">社区共建</div>
      <div class="type" @click="setShowPicker">
        <!-- <v-radio-group
          @change="change"
          :radioObj="radioObj"
          v-model="form.wishType"
        ></v-radio-group> -->
        <div class="type-title">
          <span>共建类型</span>
        </div>
        <div class="type-field">
          <span class="field-placeholder" v-show="!showMap[form.proposalType]">
            请选择
          </span>
          <span class="field-content" v-show="showMap[form.proposalType]">{{
            showMap[form.proposalType]
          }}</span>
        </div>
        <div class="type-icon">
          <img src="@/assets/img/tip.png" alt="" />
        </div>
      </div>
    </div>
    <div class="oItem-c">
      <v-input
        type="textarea"
        v-model="form.proposalContent"
        :maxlength="300"
        :showWordLimit="true"
        placeholder="亲爱的小主，社区的共建需要你们的建议，对于你们的建议，我们会进行反馈"
      />
    </div>
    <div class="oItem-b">
      <p>上传图片</p>
      <v-upload
        class="oItem-b_upload"
        :imgUrl.sync="form.proposalPic1"
        :activityPicture.sync="form.proposalPic"
        ref="load"
      ></v-upload>
      <p class="upload_text">建议单个图片不超过20M。</p>
    </div>
    <v-picker
      :columns="statusList"
      :valueShow="showPicker"
      :defaultIndex="form.wishes"
      :valueKey="'value'"
      @clickOverlay="clickPickOverlay"
      @cancel="cancelPick"
      @confirmPicker="confirmPick"
    ></v-picker>
    <div class="submit" @click="submit">确认提交</div>
  </div>
</template>

<script>
import { postWishUrl } from "./api.js";
import { toRegister } from "@/utils/common.js";
var moment = require("moment");
export default {
  name: "wishes",
  data() {
    return {
      timer: null,
      statusList: [
        {
          key: 1,
          value: "卫生",
        },
        {
          key: 2,
          value: "活动",
        },
        {
          key: 3,
          value: "公益",
        },
        {
          key: 4,
          value: "安全",
        },
      ],
      showMap: {
        1: "卫生",
        2: "活动",
        3: "公益",
        4: "安全",
      },
      form: {
        proposalPic: "",
        proposalContent: "",
        proposalType: "",
      },
      showPicker: false,
    };
  },
  destroyed() {
    clearTimeout(this.timer);
  },
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    roomId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },
  mounted() {
    // this.getActivityList();
  },
  methods: {
    setShowPicker() {
      this.showPicker = true;
    },
    clickPickOverlay() {
      this.showPicker = false;
    },
    cancelPick() {
      this.showPicker = false;
    },
    confirmPick(val) {
      this.form.proposalType = val.key;
      this.showPicker = false;
    },
    async submit() {
      if (
        await toRegister(this.$route.path, this.$route.query, "社区共建发布")
      ) {
        if (!this.form.proposalType) {
          this.$toast("请选择类型");
          return;
        }
        if (!this.form.proposalContent) {
          this.$toast("建议不能为空");
          return;
        }
        if (this.$refs.load.status == 3) {
          this.$toast("图片正在上传中");
          return;
        }

        this.postData();
      }
    },
    change() {},
    postData() {
      let params = {
        roomId: this.roomId,
        userId: this.userId,
        tenantId: this.tenantId,
        proposalContent: this.form.proposalContent,
        proposalPic: this.form.proposalPic,
        proposalType: this.form.proposalType,
      };
      this.$axios.post(`${postWishUrl}`, params).then((res) => {
        if (res.code === 200) {
          this.$toast("提交成功");
          this.timer = setTimeout(() => {
            this.$router.replace({
              name: "myCreate",
            });
          }, 200);
        } else {
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.wishes {
  min-height: 100vh;
  background: #f3f4f9;
  .oItem-t,
  .oItem-c,
  .oItem-b {
    background: #fff;
  }
  .oItem-t {
    padding: 32px 0px 16px 32px;
    .title {
      font-size: 36px;
      font-weight: bold;
      color: #1a1c34;
      line-height: 50px;
    }
    .type {
      font-size: 32px;
      font-weight: 400;
      color: #323334;
      // line-height: 44px;
      display: flex;
      padding: 32px 42px 32px 0;
      border-bottom: 2px solid rgba(0, 0, 0, 0.06);
      .type-title,
      .type-field {
        line-height: 44px;
        span {
          vertical-align: middle;
        }
      }
      .type-field {
        flex: 1;
        text-align: right;
        .field-placeholder {
          color: #cbcccd;
        }
      }
      .type-icon {
        display: flex;
        align-items: center;
        img {
          height: 34px;
          width: 34px;
          vertical-align: middle;
        }
      }
    }
  }
  .oItem-c {
    padding: 32px;
    ::v-deep .van-field {
      background: #f7f8fa;
    }
  }
  .oItem-b {
    padding: 32px;
    p {
      font-size: 32px;
      font-weight: bold;
      color: #666666;
      line-height: 44px;
    }
    .oItem-b_upload {
      width: 160px;
      height: 160px;
      background: #f7f8fa;
      margin: 32px 0 16px;
      ::v-deep .van-uploader__preview {
        border: 2px dashed #eee;
      }
      ::v-deep .van-uploader__preview-image {
        width: 160px !important;
        height: 160px !important;
        box-sizing: border-box;
      }
      ::v-deep .van-uploader__upload {
        width: 160px !important;
        height: 160px !important;
        box-sizing: border-box;
      }
      ::v-deep .van-uploader__upload {
        margin: 0;
      }
    }
    .upload_text {
      font-size: 24px;
      font-weight: 400;
      color: #96979b;
      line-height: 32px;
    }
  }
  .submit {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #fff;
    width: 100%;
    height: 96px;
    background: #178af7;
    text-align: center;
    font-size: 32px;
    line-height: 96px;
    color: #fff;
    padding-bottom: constant(safe-area-inset-bottom); /* 兼容 iOS < 11.2 */
    padding-bottom: env(safe-area-inset-bottom); /* 兼容 iOS >= 11.2 */
  }
}
</style>
<style lang="less">
.wishes {
  .van-radio__label {
    font-size: 30px;
  }
}
</style>
